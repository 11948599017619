.root {
  position: fixed;
  background-color: var(--lighter);
  border: 3px solid var(--primary);
  padding: 10px 30px 20px;
  bottom: 10px;
  left: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.buttonrow {
  width: 230px;
  margin-top: 15px;
  button {
    width: 100%;
  }
}
