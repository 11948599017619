$slideTime: 0.3s;

.question-carousel {
  max-width: 500px;
  width: 100%;
  border: 2px solid $primary;
  border-radius: 10px;

  .carousel-inner {

    .carousel-item {
      transition: -webkit-transform $slideTime ease;
      transition: transform $slideTime ease;
      transition: transform $slideTime ease, -webkit-transform $slideTime ease;
    }
  }
}
