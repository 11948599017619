// Left padding for toggle button absolute positioning
$left-padding: 25px;

.accordion {
  width: 100%;
  padding-left: $left-padding;
  .accordion-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #d4ccc9;
    .accordion-header {
      position: relative;
      width: 100%;
      background-color: inherit;
      border: none;
      text-align: left;
      padding: 0px;
      h3 {
        // H3 in header should be dark color
        color: var(--dark);
      }
      .toggle-button {
        transform: rotate(-90deg);
        transition: all 0.2s ease-in-out;
        position: absolute;
        left: $left-padding * -1;
        top: 23%;
      }
      .active {
        transform: rotate(0deg);
      }
    }
    .accordion-body {
      padding-bottom: 30px;
    }
  }
}
