@mixin dot($size) {
    height: $size;
    width: $size;
    border-radius: $size;
    margin: 0px 15px;
}

@mixin animationdelay($delay) {
    // Animate with delay
    animation: grow 0.8s linear infinite;
    animation-delay: $delay;
}

.dot1 {
    @include dot(20px);
    @include animationdelay(0s);
    background-color: var(--primary);
}

.dot2 {
    @include dot(20px);
    @include animationdelay(0.2s);
    background-color: var(--secondary);
}

.dot3 {
    @include dot(20px);
    @include animationdelay(0.4s);
    background-color: var(--autumn);
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    45% {
        transform: scale(1.7);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    }
    100% {
        transform: scale(1);
    }
}

.infotext {
    white-space: pre-wrap;
}
