.CTA-btn {
  background-color: $bright-yellow;
  border-color: $bright-yellow;
  color: $dark;
}

.CTA-btn:hover,
.CTA-btn:focus {
  border-color: darken($bright-yellow, 7);
  background-color: darken($bright-yellow, 7);
  color: $dark;
}

.CTA-btn:active {
  border-color: darken($bright-yellow, 10) !important;
  background-color: darken($bright-yellow, 10) !important;
  color: $dark !important;
}
