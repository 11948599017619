/* Override styles from the theme here */

.navbar-brand img {
  height: 46px !important;
}

.btn {
  text-transform: none !important;
  border-radius: 7px !important;
}

nav.headroom {
  background-color: var(--lighter) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(92, 122, 125, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
