.table {
  td, th {
    text-align: center;
    vertical-align: middle !important;
  }
  th {
    padding: 0.6rem;
  }
  td {
    padding: 5px;
  }
}

.icon {
  max-width: 35px;
  max-height: 35px;
}
