$cardmaxes: 600 500 400;

@each $cardmax in $cardmaxes {
  .card-max-#{$cardmax} {
    width: 90vw;
    max-width: $cardmax + px;
    .card-header {
      background-color: $gray-200;
    }
  }
}
