.messagecontainer {
  text-align: center;
  border: 2px solid var(--secondary);
  border-radius: 10px;
  padding: 30px;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
  position: relative;
}

.grow {
  transform: scale(1);
}

.shrink {
  transform: scale(0);
}

.closebutton {
  position: absolute;
  top: 10px;
  right: 10px;
}
