.logoimg {
  max-width: 100%;
  max-height: 300px;
}

.phoneimg {
  width: 100%;
  max-width: 447px;
}

[id^="#"] {
  // Elements with id="#***" are used for scrolling to
  // Account for the header height when scrolling
  scroll-margin-top: 68px;
}
