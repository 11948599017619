$boxShadow: inset 0 2px 2px rgba(0, 0, 0, 0.30);

.bar {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  box-shadow: $boxShadow;
  position: relative;
  margin: 30px 10px 20px;
}

.barfill {
  height: 10px;
  border-radius: 5px;
  position: absolute;
  box-shadow: $boxShadow;
  background-color: var(--success);
}

.checkpoint {
  position: absolute;
  top: -5px;
  height: 20px;
  width: 20px;
  margin-left: -10px;
  border-radius: 10px;
  background-color: var(--lighter);
  box-shadow: $boxShadow;
  :first-child {
    position: absolute;
    transform: translateX(-40%);
    top: -30px;
    left: 0;
    text-align: center;
  }
}

.checkpointfilled {
  @extend .checkpoint;
  background-color: var(--success);
}
