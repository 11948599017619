.main {
  min-height: 50vh;
}

.header {
  background-color: var(--lighter);
  position: relative;
}

.headerimg {
  margin-top: 30px;
  width: 100%;
  min-width: 1440px;
}

.textcont {
  position: absolute;
  top: 55%;
  transform: translateY(-55%);
  max-width: 800px;
  left: 0;
  right: 0;
  margin: auto
}
