.callcont {
  width: 600px;
}

.callparent {
  height: 800px;
}

@media only screen and (max-width: 550px) {
  .callcont {
    max-width: 500px;
    width: auto;
  }

  .callparent {
    height: 840px;
  }
}
