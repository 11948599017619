.rightsidedropdown > div {
  min-width: 0rem;
  left: auto;
  right: 0;
}

.rightsidedropdown > div::before {
  left: auto !important;
  right: 40px;
}

.profileicon {
  font-size: 1.4rem;
  display: contents;
}

.activenavlink {
  color: var(--autumn) !important;
  background-size: 100% 2px !important;
}

.deactivenavlink {
  color: var(--dark);
  font-weight: 700;
  font-size: 14px;
  background-image: linear-gradient(to right, var(--autumn), var(--autumn));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .5s ease;
}

.loginbutton {
  height: 50px;
  width: 144px;
  font-size: 14px;
}
